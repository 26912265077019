









import { defineComponent } from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';

export default defineComponent({
  name: 'PromoBar',
  components: {
    SfHeading,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'Gold',
    },
    link: {
      type: String,
    },
    linkText: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  setup(props) {
    return {
      isPromoBar: !!(props.backgroundColor && props.link && props.linkText && props.title),
      background: props.backgroundColor.toLowerCase(),
    }
  }
})
